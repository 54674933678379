import { MeasurementType, Metload, Variable } from '../metload/MetloadTypes';
import { MetserviceStation } from './models/MetserviceStation';
import { MetserviceVariable } from './models/MetserviceVariables';

export interface StationVariables {
  name: string;
  description: string;
}

export interface Station {
  id: number;
  name: string;
  latitude: number;
  longitude: number;
  elevation: number;
  variables: StationVariables[];
}

export interface MetserivceData {
  stations: Station[];
}

export interface MatchedMetserviceStation {
  station: Station;
  site?: MetserviceStation;
}

export type MetserviceMetloads = Metload & {
  meas_type: MeasurementType;
  variable: Variable;
  metserviceVariable: MetserviceVariable;
};

export const variableMap: { [key: string]: string } = {
  airtemp_01hrmax: 'Air Temperature 1hr Max',
  airtemp_01hrmin: 'Air Temperature 1hr Min',
  airtemp_01mnavg: 'Air Temperature 1hr Avg',
  cld1amt_30mnavg: 'Cloud 1 Area Fraction 30min Avg',
  cld1hgt_30mnavg: 'Cloud 1 Base Height 30min Avg',
  cld2amt_30mnavg: 'Cloud 2 Area Fraction 30min Avg',
  cld2hgt_30mnavg: 'Cloud 2 Base Height 30min Avg',
  dewtemp_01mnavg: 'Dew Point Temperature 1min Avg',
  eqptemp_01mnavg: '- 1min Avg',
  power_v_01mnavg: 'Power Voltage 1min Avg',
  presmsl_01hrmax: 'Air Pressure SL 1hr Max',
  presmsl_01hrmin: 'Air Pressure SL 1hr Min',
  presmsl_01mnavg: 'Air Pressure SL 1min Avg',
  presqfe_01hrmax: 'Air Pressure QFE 1hr Max', // QFE: field elevation pressure.
  presqfe_01hrmin: 'Air Pressure QFE 1hr Min',
  presqfe_01mnavg: 'Air Pressure QFE 1min Avg',
  presqnh_01hrmax: 'Air Pressure QNH 1hr Max', // QNH: atmospheric pressure at nautical height.
  presqnh_01hrmin: 'Air Pressure QNH 1hr Min',
  presqnh_01mnavg: 'Air Pressure QNH 1min Avg',
  pressen_01hrchg: 'Air Pressure Sensore 1hr Diff',
  pressen_01hrmax: 'Air Pressure Sensore 1hr Max',
  pressen_01hrmin: 'Air Pressure Sensore 1hr Min',
  pressen_01mnavg: 'Air Pressure Sensore 1min Avg',
  prwxnum_15mnavg: 'Present Synop Code 15 min Avg',
  prwxst1_15mnavg: 'Present METAR Code 15 min Avg',
  rainfal_01hracc: 'Rainfall 1hr Acc',
  rainfal_01hrmax: 'Rainfall 1hr Max',
  rainfal_01mnacc: 'Rainfall 1min Acc',
  rainfal_10mnacc: 'Rainfall 10min Acc',
  rainfal_10mnmax: 'Rainfall 10min Max',
  relhumd_01hrmax: 'Relative Humidity 1hr Max',
  relhumd_01hrmin: 'Relative Humidity 1hr Min',
  relhumd_01mnavg: 'Relative Humidity 1mn Avg',
  visibil_01mnavg: 'Visibility 1min Avg',
  visibil_10mnavg: 'Visibility 10min Avg',
  windccw_01hrmax: 'Wind Direction CCW 1hr Max',
  windccw_01mnmax: 'Wind Direction CCW 1min Max',
  windccw_10mnmax: 'Wind Direction CCW 10min Max',
  windcw__01hrmax: 'Wind Direction CW 1hr Max',
  windcw__01mnmax: 'Wind Direction CW 1min Max',
  windcw__10mnmax: 'Wind Direction CW 10min Max',
  winddir_01hravg: 'Wind Direction 1hr Avg',
  winddir_01mnavg: 'Wind Direction 1min Avg',
  winddir_10mnavg: 'Wind Direction 10min Avg',
  windgst_01hrdir: 'Wind Gust Direction 1hr Max',
  windgst_01hrmax: 'Wind Gust Speed 1hr Max',
  windgst_01hrtim: 'Wind Gust Time 1hr Max',
  windgst_01mnmax: 'Wind Gust Speed 1min Max',
  windgst_10mnmax: 'Wind Gust Speed 10min Max',
  windlul_01mnmin: 'Wind Lull Speed 1min Min',
  windlul_10mnmin: 'Wind Lull Speed 10min Min',
  windrun_01hracc: 'Wind run 1hr Acc',
  windspd_01hravg: 'Wind Speed 1hr Avg',
  windspd_01mnavg: 'Wind Speed 1min Avg',
  windspd_10mnavg: 'Wind Speed 10min Avg',
  wndccwm_01hrmax: 'Wind Direction CCW Magnetic 1hr Max',
  wndccwm_01mnmax: 'Wind Direction CCW Magnetic 1min Max',
  wndccwm_10mnmax: 'Wind Direction CCW Magnetic 10min Max',
  wndcwm__01hrmax: 'Wind Direction CW Magnetic 1hr Max',
  wndcwm__01mnmax: 'Wind Direction CW Magnetic 1min Max',
  wndcwm__10mnmax: 'Wind Direction CW Magnetic 10min Max',
  wnddirm_01hravg: 'Wind Direction Magnetic 1hr Avg',
  wnddirm_01mnavg: 'Wind Direction Magnetic 1in Avg',
  wnddirm_10mnavg: 'Wind Direction Magnetic 10min Avg',
  wndgstm_01hrdir: 'Wind Direction Magnetic at Max Wind Gust 1hr Avg'
  // Add more ...
};

export const getVariableName = (variableName: string) => {
  return variableMap[variableName] || variableName;
};

// This is an array of metservice station WMO IDs that we have been given access to.
// This is used to filter out stations that we don't have access to.
export const metserviceStationIds = [
  93729,
  93811,
  93851,
  93773,
  93753,
  93743,
  93797,
  93796,
  93956,
  93781,
  93765,
  93756,
  93864,
  93891,
  93878,
  93845,
  93838,
  93835,
  93834,
  93365,
  93355,
  93441,
  93397,
  93393,
  93292,
  93373,
  93191,
  93226,
  93327,
  93313,
  93309,
  93021,
  93057,
  93014,
  93034,
  93439,
  93339,
  93263,
  93179,
  93129,
  93173,
  93146,
  93247,
  93186,
  93246,
  93249,
  93098,
  93136,
  93103,
  93820,
  93661,
  93826,
  93824,
  93656,
  93819,
  93752,
  93825,
  93402,
  93467,
  93410,
  93404,
  93579,
  93546,
  93675,
  93879,
  93055,
  93023
  // Add more ...
];

