import React, { useRef, useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';

interface VariableDescriptionPopoverProps {
  name: string;
  description: string;
  hide: () => void;
}

const VariableDescriptionPopover = React.forwardRef<
  HTMLTableRowElement,
  VariableDescriptionPopoverProps
>((props, ref) => {
  const { name, description, hide } = props;
  return (
    <Popover
      id={'variable-description-popover'}
      style={{ marginLeft: 0 }}
      onClick={() => hide()}
      arrowProps={{
        ref: ref as any,
        style: {
          display: 'none'
        }
      }}
    >
      <Popover.Title as='h3' className='pl-1'>
        {name}
      </Popover.Title>
      <Popover.Content>
        <p className='mb-0'>{description}</p>
      </Popover.Content>
    </Popover>
  );
});

interface Props {
  name: string;
  description: string;
}

const VariableRow = ({ name, description }: Props) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState<EventTarget | null>(null);
  const ref = useRef<HTMLTableRowElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLTableCellElement>) => {
    setShow(!show);
    setTarget(event.target);
  };

  return (
    <tr ref={ref} style={{ position: 'relative' }}>
      <td onClick={handleClick}>{name}</td>
      <Overlay
        show={show}
        target={target as HTMLElement}
        placement='right'
        container={ref.current}
        flip
        rootClose
      >
        <VariableDescriptionPopover
          ref={ref}
          name={name}
          description={description}
          hide={() => setShow(false)}
        />
      </Overlay>
    </tr>
  );
};

export default VariableRow;

