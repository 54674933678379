import {
  faBroadcastTower,
  faCog,
  faFileAlt,
  faIdCard,
  faUserEdit
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Navbar as BootstrapNavbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { NavBarButtonProps, NavBarDropDownButtonProps } from './NavTypes';

require('./navbar.scss');

/**
 * Simple top level NavBar button.
 * @param props NavBarButtonProps.
 */
function NavBarButton(props: NavBarButtonProps) {
  return (
    <Nav.Link as={Link} to={props.to} className={props.active ? 'active' : ''}>
      {props.children}
    </Nav.Link>
  );
}

/**
 * Inner drop down NavBar button.
 * @param props NavBarDropDownButtonProps.
 */
function NavDropDownButton(props: NavBarDropDownButtonProps) {
  return (
    <Nav.Link
      as={Link}
      to={props.to}
      className={props.disabled ? 'disabled' : ''}
    >
      {props.children}
    </Nav.Link>
  );
}

/**
 * NavBar component for navigating the admin tool. Should be rendered at the
 * top of the main Container.
 */
export default function NavBar() {
  const [activeMenuItem, setActiveMenuItem] = useState('');
  const location = useLocation();

  useEffect(() => {
    const items = [
      {
        name: 'stations',
        regex: '/*'
      },
      {
        name: 'stations',
        regex: '/harvest-stations*'
      },
      {
        name: 'stations',
        regex: '/hilltop-stations*'
      },
      {
        name: 'account',
        regex: '/account*'
      },
      {
        name: 'reports',
        regex: '/reports*'
      },
      { name: 'users', regex: '/user-manager' },
      { name: 'access_array', regex: '/access-array-manager*' }
    ];

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const regExp = new RegExp(item.regex);
      if (regExp.test(location.pathname)) {
        setActiveMenuItem(location.pathname);
        break;
      }
    }
  }, [location]);

  return (
    <BootstrapNavbar bg='light' expand='md' className='p-0'>
      <BootstrapNavbar.Toggle aria-controls='basic-navbar-nav' />
      <BootstrapNavbar.Collapse id='basic-navbar-nav'>
        <Nav className='mx-auto'>
          <NavDropdown
            id='ndd'
            title={
              <BootstrapNavbar.Text>
                <FontAwesomeIcon icon={faBroadcastTower} /> Weather Stations
              </BootstrapNavbar.Text>
            }
            active={activeMenuItem === '/'}
          >
            <NavDropDownButton to='/'>
              <BootstrapNavbar.Text>Station Manager</BootstrapNavbar.Text>
            </NavDropDownButton>
            <NavDropDownButton to='/harvest-stations'>
              <BootstrapNavbar.Text>
                Harvest Stations Manager
              </BootstrapNavbar.Text>
            </NavDropDownButton>
            <NavDropDownButton to='/hilltop-stations'>
              <BootstrapNavbar.Text>
                Hilltop Stations Manager
              </BootstrapNavbar.Text>
            </NavDropDownButton>
            <NavDropDownButton to='/halo-stations'>
              <BootstrapNavbar.Text>Halo Stations Manager</BootstrapNavbar.Text>
            </NavDropDownButton>
            <NavDropDownButton to='/metservice-stations'>
              <BootstrapNavbar.Text>
                Metservice Stations Manager
              </BootstrapNavbar.Text>
            </NavDropDownButton>
            <NavDropDownButton to='/winet-stations'>
              <BootstrapNavbar.Text>
                Winet Stations Manager
              </BootstrapNavbar.Text>
            </NavDropDownButton>
            <NavDropDownButton to='/' disabled={true}>
              <BootstrapNavbar.Text>
                Taharoa Stations Manager
              </BootstrapNavbar.Text>
            </NavDropDownButton>
          </NavDropdown>
          <NavBarButton to='/reports' active={activeMenuItem === '/reports'}>
            <BootstrapNavbar.Text>
              <FontAwesomeIcon icon={faFileAlt} /> MDR Manager
            </BootstrapNavbar.Text>
          </NavBarButton>
          <NavBarButton
            to='/user-manager'
            active={activeMenuItem === '/user-manager'}
          >
            <BootstrapNavbar.Text>
              <FontAwesomeIcon icon={faUserEdit} /> User Manager
            </BootstrapNavbar.Text>
          </NavBarButton>
          <NavBarButton
            to='/access-array-manager'
            active={activeMenuItem === '/access-array-manager'}
          >
            <BootstrapNavbar.Text id='access'>
              <FontAwesomeIcon icon={faIdCard} /> Access Array Manager
            </BootstrapNavbar.Text>
          </NavBarButton>
          <NavBarButton to='/account' active={activeMenuItem === '/account'}>
            <BootstrapNavbar.Text>
              <FontAwesomeIcon icon={faCog} /> Account
            </BootstrapNavbar.Text>
          </NavBarButton>
        </Nav>
      </BootstrapNavbar.Collapse>
    </BootstrapNavbar>
  );
}

